import React from "react";

const Teach = () => {
  return (
    <div className="inner-page">
      <div className="container">
        <div style={{ textAlign: "center", margin: "200px 0px" , fontSize: 20}}>Teach</div>
      </div>
    </div>
  );
};

export default Teach;
