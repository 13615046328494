import React from "react";
import Homepage from "./pages/Homepage";
import { Routes, Route } from "react-router";
import Layout from "./layouts/layout";
import Login from "./pages/auth/Login";
import BecomeTutor from "./pages/BecomeTutor";
import FindTutor from "./pages/FindTutor";
import Cart from "./pages/Cart";
import SignUp from "./pages/auth/SignUp";
import Teach from "./pages/Teach";
import ForgotPassword from "./pages/auth/ForgotPassword";
import HelpPage from "./pages/HelpPage";

const Navigation = () => {
  const NotFound = () => {
    return (
      <div
        style={{ marginTop: "200px", minHeight: "50vh", textAlign: "center" }}
      >
        Not Found
      </div>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Homepage />} />
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="teach" element={<Teach />} />
        <Route path="become-tutor" element={<BecomeTutor />} />
        <Route path="find-tutors" element={<FindTutor />} />
        <Route path="cart" element={<Cart />} />
        <Route path="/*" element={<NotFound />} />
      </Route>
      {/* <Route path="/help" element={<HelpPage />} /> */}
    </Routes>
  );
};

export default Navigation;
