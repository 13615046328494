import React from "react";

const Cart = () => {
  return (
    <div
      style={{
        marginTop: "200px",
        minHeight: "50vh",
        textAlign: "center",
        fontSize: 20,
        fontWeight: 700,
      }}
    >
      Cart
    </div>
  );
};

export default Cart;
