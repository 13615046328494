import React from "react";

const Footer = () => {
  return (
    <section class="footer_section">
      <div class="container">
        <footer>
          <div class="footer_logo_wrapper">
            <div class="footer_logo">
              <img
                src={require("../assets/images/footer_logo.svg").default}
                alt="footer_logo"
              />
            </div>
            <div class="footer_support_wrapper">
              <a href="#">Support</a>
            </div>
            <div class="footer_help_wrapper">
              <a href="#">Need Any Help?</a>
            </div>
          </div>
          <div class="footer_contact_wrapper">
            <h3>Contacts</h3>
            <div class="nationality">
              <img
                src={require("../assets/images/nationality.svg").default}
                alt="nationality"
              />
              <span>USA</span>
            </div>
            <div class="footer_adders">
              <p>1309 Beacon Street, Suite 300,</p>
              <p>Brookline, ΜΑ, 02446r</p>
            </div>
          </div>
          <div class="footer_social_media">
            <h3>Social Media</h3>
            <ul>
              <li>
                <a href="#">
                  <img
                    src={require("../assets/images/favebook_iocn.svg").default}
                    alt="facebook"
                  />
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src={require("../assets/images/instagram_icon.svg").default}
                  />
                  <span>Instagram</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src={require("../assets/images/you_tube_icon.svg").default}
                    alt="you_tube"
                  />
                  <span>Youtube</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src={require("../assets/images/linked_in_icon.svg").default}
                    alt="linked_in"
                  />
                  <span>LinkedIn</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src={require("../assets/images/tik_tok_icon.svg").default}
                    alt="tik_tok"
                  />
                  <span>Tik Tok</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="footer_apps_wrapper">
            <h3>Apps</h3>
            <div class="apps_img">
              <a href="#">
                <img
                  src={require("../assets/images/app_store.svg").default}
                  alt="app_store"
                />
              </a>
              <a href="#">
                <img
                  src={require("../assets/images/google_play.svg").default}
                  alt="google_play"
                />
              </a>
            </div>
          </div>
        </footer>
      </div>
      <div class="privacy_policy_section">
        <div class="container">
          <div class="policy_year">
            <p>©2012-2024 Preply Inc.</p>
          </div>
          <div class="privacy_terms">
            <ul>
              <li>
                <a href="#">Legal Center</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Cookie Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
