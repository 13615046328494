import React, { useState } from "react";
import Button from "../../components/Button";
import InputField from "../../components/InputField";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const onClick = (e) => {
    e.preventDefault();
    console.log(email);
  };

  return (
    <div className="inner-page forget_page">
      <div className="small-container">
        <div style={{ fontSize: "30px", fontWeight: "bolder" }}>
          Forgot password
        </div>
        <form onSubmit={onClick}>
          <InputField
            value={email}
            name={"email"}
            type={"text"}
            label={"Your email"}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={"Enter your email address"}
          />
          <Button text={"Submit"} onClick={onClick} />
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
