import React, { useState } from "react";
import { EyeClose, EyeOpen } from "../utils/Icons";

const InputField = (props) => {
  const { value, onChange, label, name, type, placeholder } = props;

  const [showpass, setShowPass] = useState(false);

  return (
    <div className="input-field-container">
      <label className="input-label">{label}</label>
      <div
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        <input
          className="input-field"
          style={{ paddingRight: type == "password" ? "45px" : "14px" }}
          type={type == "password" ? (showpass ? "text" : "password") : type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
        />
        {type == "password" && (
          <div
            className="input-field-password-eye"
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 14,
              width: "25px",
              height: "25px",
            }}
            onClick={() => setShowPass(!showpass)}
          >
            {showpass ? <EyeOpen /> : <EyeClose />}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputField;
