import React from "react";

const Button = (props) => {
  const { text, loading, onClick } = props;
  return (
    <button className="custom-button" onClick={onClick}>
      {loading ? "loading...." : text}
    </button>
  );
};

export default Button;
