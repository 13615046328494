import React from "react";
import { CheckIcon } from "../utils/Icons";

const CheckBox = (props) => {
  const { value, setValue, text = "I Accept" } = props;

  return (
    <div style={{ display: "flex", margin: "20px 0px" , alignItems:'center' }}>
      <div
        onClick={setValue}
        style={{
          border: "1px solid lightgray",
          borderRadius: 5,
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: value ? "#000" : "#fff",
        }}
      >
        {value && <CheckIcon />}
      </div>
      <div style={{marginLeft: '10px'}}>{text}</div>
    </div>
  );
};

export default CheckBox;
