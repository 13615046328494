import React from "react";

const FindTutor = () => {
  return (
    <div
      style={{
        marginTop: "200px",
        minHeight: "50vh",
        textAlign: "center",
        fontSize: 20,
        fontWeight: 700,
      }}
    >
      Find Tutors
    </div>
  );
};

export default FindTutor;
