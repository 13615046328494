import React, { useState } from "react";
import InputField from "../../components/InputField";
import Separator from "../../components/Seprator";
import { AppleLogo, GoogleLogo, FacebookLogo } from "../../utils/Icons";
import Button from "../../components/Button";
import CheckBox from "../../components/CheckBox";
import { Link } from "react-router";

const Login = () => {
  const [loginData, setLoginData] = useState({});
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(false);

  const onChange = (e) => {
    let { name, value } = e.target;
    let _data = { ...loginData };

    _data[name] = value;

    if (_data[name].trim() == "") {
      delete _data[name];
    }

    setLoginData(_data);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    console.log(loginData);
  };

  return (
    <div className="inner-page Login_page">
      <div className="small-container">
        <h3 className="login-title">Log in</h3>
        <div className="login-header">
          <Link to={"/sign-up"} className="signup-links">
            Sign up as a student
          </Link>{" "}
          <span style={{ margin: "0px 8px" }}>or</span>{" "}
          <Link to={"/teach"} className="signup-links">
            Sign up as a tutor
          </Link>
        </div>
        <div className="social-buttons-container">
          <div className="social-button">
            <div className="logo">
              <GoogleLogo />
            </div>
            Continue with Google
          </div>
          <div className="social-button">
            <div className="logo">
              <FacebookLogo />
            </div>
            Continue with Facebook
          </div>
          <div className="social-button">
            <div className="logo">
              <AppleLogo />
            </div>
            Continue with Apple
          </div>
        </div>
        <Separator text={"or"} color={"lightgray"} />
        <form onSubmit={handleLogin}>
          <InputField
            value={loginData["email"] || ""}
            name={"email"}
            type={"email"}
            label={"Email"}
            onChange={onChange}
            placeholder={"Your Email"}
          />
          <InputField
            value={loginData["password"] || ""}
            name={"password"}
            type={"password"}
            label={"Password"}
            onChange={onChange}
            placeholder={"Your Password"}
          />
          <Link to={"/forgot-password"} className="forget-password">
            Forgot your password?
          </Link>
          <CheckBox
            value={remember}
            setValue={() => setRemember(!remember)}
            text={"Remember me"}
          />
          <Button text={"Log in"} loading={loading} onClick={handleLogin} />
        </form>

        <div className="terms-container">
          <div>By clicking Log in or Continue with, you agree to Preply </div>{" "}
          <Link to={"/"} className="links">
            Terms of use{" "}
          </Link>{" "}
          and{" "}
          <Link to={"/"} className="links">
            {" "}
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
