import React from "react";

const Separator = (props) => {
  const { text = "or", color = "gray" } = props;
  return (
    <div className="line_or"
     
    >
      <p
      className="or"
      >
        {text}
      </p>
    </div>
  );
};

export default Separator;
